import React, { Suspense, useContext, useReducer } from "react";
import ReactDOM from "react-dom";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./redux/store";
import Clinet from "./Client";
import Context from "./store/Context";
import Reducer from "./store/Reducer";
import { WebSocketProvider } from "./store/WebSocketContext";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));
const Store = (props) => {
  const initialState = useContext(Context);
  const [state, dispatch] = useReducer(Reducer, initialState);
  // useEffect(() => {
  //   try {
  //     if (localStorage.getItem("version") != process.env.REACT_APP_VERSION) {
  //       caches.keys().then((names) => {
  //         names.forEach((name) => {
  //           caches.delete(name);
  //         });
  //       });
  //       // حل فاشل لانه يحذف ال token
  //       // window.localStorage.clear(); //clear all localstorage
  //       window.localStorage.removeItem("__theme_selected_color");
  //       localStorage.setItem("version", process.env.REACT_APP_VERSION);
  //       // window.history.forward(1);
  //       window.location.reload(true);
  //     }
  //   } catch (error) {}
  // }, []);
  return (
    <Context.Provider value={{ state, dispatch }}>
      {props.children}
    </Context.Provider>
  );
};

ReactDOM.render(
  <ClearBrowserCacheBoundary auto fallback="Loading" duration={60000}>
    <ApolloProvider client={Clinet}>
      <Store>
        <Provider store={configureStore()}>
          <Suspense fallback={<div className="loading" />}>
            <WebSocketProvider>
              <App />
            </WebSocketProvider>
          </Suspense>
        </Provider>
      </Store>
    </ApolloProvider>
  </ClearBrowserCacheBoundary>,
  document.getElementById("root")
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
