import {
    ACTIVE_PRODUCT, ADDRESS, AUCTIONS_EXAMPLE, AUCTION_BIDS, AUCTION_DETAILS,
    BROCHUREFILE, BID_INCREMENT,
    COUNTDOWN, HIGHEST_BID, LAST_BID_ID, LOGOFILE, MAP, NAMETRANS, RESET, STARTDATE, STATUS_NAME, STATUS_TYPE,
    TERMSTRANS,
    UPDATE_BIDS,
    VIDEOURL,
    START_PRICE
} from './types';
const initialState = {
    auctionsExample: ['hi', 'hello'],
    auctionDetails: {},
    auctionBids: null,
    activeProduct: null,
    countdown: null,
    highestBid: null,
    bidIncrement: null,
    startPrice: null,
    statusType: null,
    statusName: '',
    bids: [],
    lastBidId: null,
    nameTrans: '',
    videoURL: '',
    map: '',
    address: '',
    startDate: '',
    termsTrans: '',
    brochureFile: '',
    logoFile: '',
}
const AuctionStore = (state = initialState, action) => {
    switch (action.type) {
        case AUCTIONS_EXAMPLE:
            return {
                ...state,
                auctionsExamples: action.payload
            }
        case AUCTION_DETAILS:
            return {
                ...state,
                auctionDetails: action.payload
            }
        case AUCTION_BIDS:
            return {
                ...state,
                auctionBids: action.payload
            }
        case ACTIVE_PRODUCT:
            return {
                ...state,
                activeProduct: action.payload
            }
        case COUNTDOWN:
            return {
                ...state,
                countdown: action.payload
            }
        case HIGHEST_BID:
            return {
                ...state,
                highestBid: action.payload
            }
        case BID_INCREMENT:
            return {
                ...state,
                bidIncrement: action.payload
            }
        case START_PRICE:
            return {
                ...state,
                startPrice: action.payload
            }
        case LAST_BID_ID:
            return {
                ...state,
                lastBidId: action.payload
            }
        case STATUS_TYPE:
            return {
                ...state,
                statusType: action.payload
            }
        case STATUS_NAME:
            return {
                ...state,
                statusName: action.payload
            }
        case UPDATE_BIDS:
            return {
                ...state,
                bids: action.payload
            }
        case NAMETRANS:
            return {
                ...state,
                nameTrans: action.payload
            }
        case VIDEOURL:
            return {
                ...state,
                videoURL: action.payload
            }
        case MAP:
            return {
                ...state,
                map: action.payload
            }
        case ADDRESS:
            return {
                ...state,
                address: action.payload
            }
        case STARTDATE:
            return {
                ...state,
                startDate: action.payload
            }
        case TERMSTRANS:
            return {
                ...state,
                termsTrans: action.payload
            }
        case BROCHUREFILE:
            return {
                ...state,
                brochureFile: action.payload
            }
        case LOGOFILE:
            return {
                ...state,
                logoFile: action.payload
            }
        case RESET:
            return {
                ...state,
                nameTrans: '',
                videoURL: '',
                map: '',
                address: '',
                startDate: '',
                termsTrans: '',
                brochureFile: '',
                logoFile: '',
            }
        default:
            state = { ...state }
            break;
    }
    return state
}
export default AuctionStore