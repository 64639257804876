const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL;

class WebSocketService {
  constructor(url) {
    if (!WebSocketService.instance) {
      this.url = url;
      this.socket = null;
      this.isConnected = false;
      this.reconnectAttempts = 0;
      this.maxReconnectAttempts = 5;
      this.onStatusChange = null;
      this.pendingMessages = [];
      this.pingInterval = null;
      WebSocketService.instance = this;
      this.connect();
    }
    return WebSocketService.instance;
  }

  connect() {
    try {
      if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
        this.socket = new WebSocket(this.url);

        this.socket.onopen = () => {
          this.isConnected = true;
          this.reconnectAttempts = 0;
          if (this.onStatusChange) this.onStatusChange(this.isConnected);

          this.pendingMessages.forEach((msg) => this.sendMessage(msg));
          this.pendingMessages = [];

          this.keepAlive();
        };

        this.socket.onclose = (event) => {
          this.isConnected = false;
          if (this.onStatusChange) this.onStatusChange(this.isConnected);

          if (event.code !== 1000) {
            this.reconnect();
          }

          if (this.socket) this.socket = null;
        };

        this.socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          if (this.socket) this.socket.close();
        };
      }
    } catch (error) {
      console.error("WebSocket error:", error);
    }
  }

  reconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts += 1;
      this.connect();
    } else {
      console.error("Max reconnect attempts reached. Failed to reconnect.");
    }
  }

  onMessage(callback) {
    if (this.socket) {
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        callback(data);
      };
    }
  }

  sendMessage(message) {
    if (this.socket && this.isConnected) {
      this.socket.send(JSON.stringify(message));
    } else {
      this.pendingMessages.push(message);
    }
  }

  keepAlive() {
    this.stopPing();

    this.pingInterval = setInterval(() => {
      this.sendMessage({ action: "ping" });
    }, 30000);
  }

  stopPing() {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }

  close() {
    if (this.socket) {
      this.socket.close();
      this.isConnected = false;
      this.socket = null;
      if (this.onStatusChange) this.onStatusChange(this.isConnected);
    }
  }

  setStatusChangeCallback(callback) {
    this.onStatusChange = callback;
  }
}

const webSocketService = new WebSocketService(WSS_BASE_URL);

export default webSocketService;
