export const AUCTIONS_EXAMPLE = 'auctionsExample';
export const AUCTION_DETAILS = 'auctionDetails';
export const AUCTION_BIDS = 'AUCTION_BIDS';
export const ACTIVE_PRODUCT = 'ACTIVE_PRODUCT';
export const COUNTDOWN = 'COUNTDOWN';
export const HIGHEST_BID = 'HIGHEST_BID';
export const BID_INCREMENT = 'BID_INCREMENT';
export const START_PRICE = 'START_PRICE';
export const STATUS_TYPE = 'STATUS_TYPE';
export const STATUS_NAME = 'STATUS_NAME';
export const UPDATE_BIDS = 'UPDATE_BIDS';
export const LAST_BID_ID = 'LAST_BID_ID';
export const NAMETRANS = 'NAMETRANS';
export const VIDEOURL = 'VIDEOURL';
export const MAP = 'MAP';
export const ADDRESS = 'ADDRESS';
export const STARTDATE = 'STARTDATE';
export const TERMSTRANS = 'TERMSTRANS';
export const BROCHUREFILE = 'BROCHUREFILE';
export const LOGOFILE = 'LOGOFILE';
export const RESET = 'RESET';