/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* MENU */
export const MODAL_OPENING = "MODAL_OPENING";
export const MODAL_CONTENT = "MODAL_CONTENT";
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";
export const OPEN_MODAL = "OPEN_MODAL";
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auctions/actions"
