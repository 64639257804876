import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
  import webSocketService from "../helpers/utils/wss";
  
  const WebSocketContext = createContext();
  
  export const WebSocketProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [isConnected, setIsConnected] = useState(webSocketService.isConnected);
  
    const sendMessage = useCallback((message) => {
      webSocketService.sendMessage(message);
    }, []);
  
    useEffect(() => {
      webSocketService.onMessage((message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });
  
      webSocketService.setStatusChangeCallback((status) => {
        setIsConnected(status);
      });
  
      return () => {
        webSocketService.close();
      };
    }, []);
  
    const values = useMemo(
      () => ({ messages, sendMessage, isConnected }),
      [messages, sendMessage, isConnected]
    );
  
    return (
      <WebSocketContext.Provider value={values}>
        {children}
      </WebSocketContext.Provider>
    );
  };
  
  export const useWebSocket = () => useContext(WebSocketContext);
  