import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import auction from './auctions/reducer'

const reducers = combineReducers({
  menu,
  settings,
  auction
});

export default reducers;
