import { createContext } from "react";
import { INIT_STATE } from "../redux/settings/reducer";
import { USER_INIT_STATE } from "./user_auth/reducer";

const Context = createContext({
  locale: INIT_STATE,
  currentUser: USER_INIT_STATE,
});

export default Context;
